<template>
    <v-btn
      :id="id"
      class="btn-ag-tertiary"
      @click="onClick()"
      :disabled="disabled"
      icon
    >
      <v-icon>mdi-download-outline</v-icon> {{ txt.export }}
    </v-btn>
  </template>
  <script>
  
  import { uniqueId } from 'lodash';

  export default {
    name: 'ExportButton',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        id: {
          type: String,
          default: uniqueId('btn_export_')
        }
    },
  
    data() {
      return {
        txt: {
            export: 'Exportar'
        }
      };
    },
  
    methods: {
      onClick() {
        this.$emit('click');
      },
    },
  };
  </script>

  <style scoped lang="scss">  
  </style>
  