var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "btn-ag-tertiary",
      attrs: { id: _vm.id, disabled: _vm.disabled, icon: "" },
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
      },
    },
    [
      _c("v-icon", [_vm._v("mdi-download-outline")]),
      _vm._v(" " + _vm._s(_vm.txt.export) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }